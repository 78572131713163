import React from 'react';
import PropTypes from 'prop-types';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import {
  Typography,
} from '@mui/material';
import {
  MenuItem, Select, CustomInput, SelectedIcon,
} from './SelectionDropdown.styles';

/**
 * Selection Dropdown React Component
 */
function SelectionDropdown({
  selectedCallback,
  optionsList = [],
  selectedIndex = 0,
  id = '',
  ariaLabel = '',
  parentComponentName = '',
}) {
  const singleOption = optionsList?.length === 1;

  let currentIndex = selectedIndex;
  const [state, setState] = React.useState({
    value: optionsList?.[currentIndex] || optionsList?.[0],
  });

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    currentIndex = optionsList.findIndex((option) => option.value === selectedValue && option);
    setState({
      ...state,
      value: optionsList[currentIndex],
    });
    selectedCallback(selectedValue);
  };
  return (
    <Select
      data-component={componentNameToMyPhxKebabFormat(`${parentComponentName}SelectionDropdown`)}
      $singleOptionView={singleOption}
      onChange={handleChange}
      value={state?.value?.value}
      renderValue={() => state?.value?.title}
      displayEmpty
      disabled={singleOption}
      id={id}
      variant="standard"
      MenuProps={{
        marginThreshold: 0,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      input={<CustomInput />}
      aria-label={ariaLabel}
      SelectDisplayProps={{ role: 'combobox' }}
    >
      {optionsList?.map((ele) => (
        <MenuItem
          value={ele.value}
          key={ele.value}
          aria-label={ele.ariaLabel || ele.title}
          classes={{ select: 'checkmark' }}
        >
          <Typography variant="body4">
            <SelectedIcon icon="icon-checkmark" className="checkmark" />
            {ele.title}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
}

SelectionDropdown.propTypes = {
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      title: PropTypes.string.isRequired,
      ariaLabel: PropTypes.string,
    }),
  ).isRequired,
  selectedCallback: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default SelectionDropdown;
